import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import LatestCpt from '../bvv-styleguide/components/latest-cpt/LatestCpt'
import ImageIntroText from '../bvv-styleguide/components/image-intro-text/ImageIntroText'

export const ArchivePageTemplate = ({ content }) => {
  return (
    <main className="content" dangerouslySetInnerHTML={{ __html: content }} />
  )
}

ArchivePageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

const ArchivePage = ({ data }) => {
  const {
    allWordpressWpNews: { edges },
  } = data
  const [loadedPosts, setLoadedPosts] = useState(8)
  let allNews = []
  edges.map(news => allNews.push(news.node))
  const getOlderCpt = () => setLoadedPosts(loadedPosts + 6)
  let firstNews = allNews.splice(0, 1)

  return (
    <Layout>
      <Seo title="Fréttir" />
      <ImageIntroText
        title={firstNews[0].title}
        text={firstNews[0].content}
        postDate={firstNews[0].date}
        buttonLink={`${firstNews[0].slug}`}
        bgImageUrl={
          firstNews[0].featured_media === null
            ? firstNews.featured_image_src
            : firstNews[0].featured_media.source_url
        }
      />
      <LatestCpt
        title="Fréttir"
        cptName="news"
        posts={allNews}
        isOnArchive={true}
        onClick={getOlderCpt}
        loadedPosts={loadedPosts}
      />
    </Layout>
  )
}

ArchivePage.propTypes = {
  data: PropTypes.object.isRequired,
}
export default ArchivePage

export const archivePageQuery = graphql`
  query newsArchivePage {
    allWordpressWpNews {
      edges {
        node {
          content
          link
          slug
          date
          title
          featured_media {
            source_url
          }
        }
      }
    }
  }
`
